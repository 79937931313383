import React, { useContext, useState, useEffect } from "react";
import UserContext from "../context/UserContext";
import escrow from '../contracts/escrow';
import '../css/components.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import web3 from "../web3";

const NewEscrow = () => {
  const { user } = useContext(UserContext);
  const [recipient, setRecipient] = useState("");
  const [amount, setAmount] = useState("");
  const [escrowId, setEscrowId] = useState("");
  const [message, setMessage] = useState("");
  const [renderState, setRenderState] = useState("");
  let componentToRender;

  useEffect(()=>{
    const handleEscrowCreated = (error, event) => {
        if (error) {
          console.error("Error in escrowCreated event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { Sender, Receiver, Amount, escrowNumber } = event.returnValues;
        if(Sender.toLowerCase()===user.walletAddress.toLowerCase()){
          const data = `Escrow created with Details: 
                        \nSender: ${Sender} 
                        \nReceiver: ${Receiver} 
                        \nAmount: ${Amount}
                        \nEscrow ID: ${escrowNumber}`;
          const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          console.log(event.returnValues);
          setMessage(formattedData);
        }
        
      };
  
      const handleEscrowWithdrawalRequested = (error, event) => {
        if (error) {
          console.error("Error in escrowWithdrawalRequested event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { escrowNumber } = event.returnValues;
        if (escrowNumber === escrowId) {
          const data = `Escrow withdrawal request created for following escrow: 
                        \nEscrow ID: ${escrowNumber}`;
          const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          setMessage(formattedData);
        }
      };
  
      const handleEscrowAgreed = (error, event) => {
        if (error) {
          console.error("Error in escrowAgreed event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { escrowNumber } = event.returnValues;
        if (escrowNumber === escrowId) {
          const data = `Escrow agreement request accepted for followinf escrow: 
                        \nEscrow ID: ${escrowNumber}
                        \nEscrow Tokens trasferred to escrow recepient.`;
          const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          setMessage(formattedData);
        }
      };

      const handleEscrowDisputed = (error, event) => {
        if (error) {
          console.error("Error in escrowDisputed event:", error);
          return;
        }
        if (!event || !event.returnValues) {
          console.error("Invalid event data:", event);
          return;
        }
  
        const { escrowNumber } = event.returnValues;
        if (escrowNumber === escrowId) {
          const data = `Escrow dispute request generated for following escrow: 
                        \nEscrow ID: ${escrowNumber}
                        \nCreate a new topic in "Disputes" forum at https://freecryptotools.org`;
          const formattedData = data.split('\n').map((str, index) => <p key={index}>{str}</p>);
          setMessage(formattedData);
        }

      };
  
      const escrowCreatedSubscription = escrow.events.escrowCreated({}, handleEscrowCreated);
      const escrowWithdrawalRequestedSubscription = escrow.events.escrowWithdrawalRequested({}, handleEscrowWithdrawalRequested);
      const escrowAgreedSubscription = escrow.events.escrowAgreed({}, handleEscrowAgreed);
      const escrowDisputedSubscription = escrow.events.escrowDisputed({}, handleEscrowDisputed);
  
      return () => {
        escrowCreatedSubscription.unsubscribe();
        escrowWithdrawalRequestedSubscription.unsubscribe();
        escrowAgreedSubscription.unsubscribe();
        escrowDisputedSubscription.unsubscribe();
      };
  },[escrowId, user.walletAddress]);
  

  const startEscrow = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

        setMessage('Waiting for transaction...');
        console.log(message);

        if(!web3.utils.isAddress(recipient)){
          setMessage("Recipient address is invalid");
          return;
        }
        
        try{
            await escrow.methods.startEscrow(amount, recipient).send({
            from: user.walletAddress
            });
        } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                console.log(err);
        }
      }
  }

  const withdrawRequest = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

        setMessage('Escrow Withdrawal Requested: Waiting for transaction...');
        
        try{
            await escrow.methods.withdrawRequest(escrowId).send({
            from: user.walletAddress
            });
        } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  const acceptRequest = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

        setMessage('Escrow Accepted: Waiting for transaction...');
        
        try{
            await escrow.methods.acceptRequest(escrowId).send({
            from: user.walletAddress
            });
            } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  const createDispute = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet')
      alert('Connect wallet first');
      

    else {

        setMessage('Escrow dispute raise Requested: Waiting for transaction...');
        
        try{
            await escrow.methods.CreateDispute(escrowId).send({
            from: user.walletAddress
            });
        } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  const escrowDetails = async (event) =>{

    event.preventDefault();

    if (user.walletAddress === 'Connect wallet'){
      alert('Connect wallet first');
      return;
    }
      

    else {

        let details;
        let status;

        setMessage('Escrow details Requested: Waiting for transaction...');
        
        try{
            details = await escrow.methods.escrowDetails(escrowId).call();
            status = await escrow.methods.escrowStatus(escrowId).call();
            if(!details){
                setMessage('No details found about escrow on blockchain');
                return;
            }
            let currentEscrowStatus;
            switch(status){
                case '0': currentEscrowStatus = "Escrow has been created"; break;
                case '1': currentEscrowStatus = "Escrow withdrawal request has been generated by recipient"; break;
                case '2': currentEscrowStatus = "Escrow is in dispute";  break;
                case '3': currentEscrowStatus = "Escrow amount has been transferred to the recepient by the sender"; break;
                case '4': currentEscrowStatus = "Escrow has been settled by admin"; break;
                default : currentEscrowStatus = "Could not retrieve escrow status"; break;

            }

            const data = `Escrow Details: 
                            \nSender: ${details.Sender} 
                            \nReceiver: ${details.Receiver} 
                            \nAmount: ${details.Amount}
                            \nEscrow Status: ${currentEscrowStatus}`;

            const formattedData = data.split('\n').map(str => <p>{str}</p>);
            setMessage(formattedData);
        } 
        catch(err){
            if (err.code === 4001)
                alert('Transaction rejected');
            else
                setMessage(err);
        }
      }
  }

  const updateComponent = (whichComponentToRender) => {
    setRenderState(whichComponentToRender);

  }

  switch(renderState){
    case 'startEscrow': 
        componentToRender =<div>
        
            <form onSubmit={startEscrow}>
                <div className="form-group">
                    <label>Recipient</label>
                    <input
                    value={recipient}
                    onChange={event => setRecipient(event.target.value)}
                    />
                </div>
                <div className="form-group">
                    <label>Amount </label>
                    <input
                    value={amount}
                    onChange={event => setAmount(event.target.value)}
                    type="number"
                    min={0}
                    />
                </div>
                <div className="form-group">
                    <button>Start</button>
                </div>
            </form>
            <i className="form-group">You must be sender of the escrow to call this function.</i>
        </div>;  
    break;
    case 'withdrawRequest': 
        componentToRender = <div>    
            <form onSubmit={withdrawRequest}>
                <div className="form-group">
                    <label>Escrow ID</label>
                    <input
                    value={escrowId}
                    onChange={event => setEscrowId(event.target.value)}
                    type="number"
                    min={0}
                    />
                </div>
                <div className="form-group">
                    <button>Start</button>
                </div>
            </form>
            <i className="form-group">You must be recepient of escrow to call this function.</i>
        </div>;
    break;
    case 'acceptRequest': 
        componentToRender = <div>
            <form onSubmit={acceptRequest}>
            <div className="form-group">
                <label>Escrow ID </label>
                <input
                value={escrowId}
                onChange={event => setEscrowId(event.target.value)}
                type="number"
                min={0}
                />
            </div>
            <div className="form-group">
                <button>Start</button>
            </div>
        </form>
        <i className="form-group">You must be sender of the escrow to call this function.</i>
        </div>;
    break;
    case 'createDispute':
        componentToRender = <div>
            <form onSubmit={createDispute}>
            <div className="form-group">
                <label>Escrow ID </label>
                <input
                value={escrowId}
                onChange={event => setEscrowId(event.target.value)}
                type="number"
                min={0}
                />
            </div>
            <div className="form-group">
                <button>Start</button>
            </div>
        </form>
        <i className="form-group">You must be sender/recepient of the escrow to call this function.</i>
        </div>;
        break;
        case 'escrowDetails':
            componentToRender = <div>
                <form onSubmit={escrowDetails}>
                <div className="form-group">
                    <label>Escrow ID </label>
                    <input
                    value={escrowId}
                    onChange={event => setEscrowId(event.target.value)}
                    type="number"
                    min={0}
                    />
                </div>
                <div className="form-group">
                    <button>Start</button>
                </div>
            </form>
            </div>;
            break;
    default: 
        componentToRender = <div></div>;
    break;
  }

  return (
    <div className="rendered-container">
      <div className="button-container justify-content-center">
        <button className="m-1 button ${renderState === 'startEscrow' ? 'active' : ''}`" onClick={() => updateComponent('startEscrow')}>
            Start New Escrow</button>
        <button className="m-1 button ${renderState === 'withdrawRequest' ? 'active' : ''}`" onClick={() => updateComponent('withdrawRequest')}>Withdrawal Request</button>
        <button className="m-1 button ${renderState === 'acceptRequest' ? 'active' : ''}`" onClick={() => updateComponent('acceptRequest')}>Accept Withdrawal</button>
        <button className="m-1 button ${renderState === 'createDispute' ? 'active' : ''}`" onClick={() => updateComponent('createDispute')}>Create Dispute</button>
        <button className="m-1 button ${renderState === 'escrowDetails' ? 'active' : ''}`" onClick={() => updateComponent('escrowDetails')}>Escrow Details</button>
      </div>
      <div>{componentToRender}</div><hr />
        <h6>Most recent transaction details:
        </h6>
      <div  className="rendered-container">
        {message}
      </div>
    </div>
  );
};


export default NewEscrow;