import React, { useContext } from "react";
import MenuContext from "../context/MenuContext";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import NewEscrow from "./NewEscrow";
import Airdropper from "./Airdropper";

const MainContent = () => {
    const { menuState, setMenuState } = useContext(MenuContext);
    let componentToRender;
    switch (menuState.activeItem){
        case 'NewEscrow':
            componentToRender = <div><NewEscrow /></div>;
            break;
        case 'Airdrop':
            componentToRender = <div><Airdropper /></div>; break;
        default:
            componentToRender = <div><h3>Select a process from menu</h3></div>
    }



    return(
        <div className=' p-2 d-flex justify-content-center'>
            <div>
                {componentToRender}
            </div>
            
        </div>
    );

};

export default MainContent;