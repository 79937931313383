import React, { useState } from "react";

import MenuContext from "./MenuContext";

const MenuContextProvider = ({ children }) => {
  const [menuState, setMenuState] = useState({ activeItem: 'none' }) // Initial state as an object

  return (
    <MenuContext.Provider value={{menuState, setMenuState}}>
      {children}
    </MenuContext.Provider>
  );
};

export default MenuContextProvider;
