import React, { useContext, useState } from "react";
import MenuContext from "../context/MenuContext";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import '../css/components.css';

const Sidebar = () =>{
    const { menuState, setMenuState } = useContext(MenuContext);

    const [isCollapsed, setIsCollapsed] = useState(true); // State to track sidebar visibility

    const toggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
      };
   
    const handleMenuClick = (functionality) => {
        setMenuState({activeItem: functionality});
        toggleCollapse();
    }


    return(
        <nav className={`navbar navbar-expand-lg navbar-light bg-light ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="container-fluid">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isCollapsed} // Set aria-expanded based on collapse state
          aria-label="Toggle navigation"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${isCollapsed ? '' : 'show'}`} id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item p-2 link-style" onClick={() => handleMenuClick('NewEscrow')}>
                <strong>Escrow</strong>
            </li>
            <li className="nav-item p-2 link-style" onClick={() => handleMenuClick('Airdrop')}>
                <strong>Airdrop</strong>
            </li>
            <li className="nav-item p-2 link-style" onClick={() => window.open("https://freecryptotools.org/", "_blank")}>
                <strong>Forum</strong>
            </li>
            {/* Add more menu items here */}
          </ul>
        </div>
      </div>
    </nav>
    );
};

export default Sidebar;