import web3 from "../web3";

const address = "0x8b66d89a109eea2d1390637adb37681276dc8fb6";

const abi = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "smartContract",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint8",
				"name": "decimals",
				"type": "uint8"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "airdropCreator",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "address",
				"name": "newAirdropper",
				"type": "address"
			}
		],
		"name": "newAirdropperCreated",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "tokenSmartcontract",
				"type": "address"
			},
			{
				"internalType": "uint8",
				"name": "decimals",
				"type": "uint8"
			},
			{
				"internalType": "uint128",
				"name": "rewards",
				"type": "uint128"
			}
		],
		"name": "createNewAirdropper",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	}
];

export default new web3.eth.Contract(abi, address);