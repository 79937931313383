import React, { useContext, useState, useEffect } from "react";
import UserContext from "../context/UserContext";
import shutdown from "../images/shutdown512x512.png"
import "../css/components.css"

const ConnectWallet = () => {

  const { user, setUser } = useContext(UserContext);
  const [isConnecting, setIsConnecting] = useState(false);

  const connectWallet = async (event) => {
    event.preventDefault();
    setIsConnecting(true);

    // Check if MetaMask is installed on user's browser
    if (window.ethereum.isMetaMask) {
      try {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        if (accounts.length > 0) {
          await setUser({ walletAddress: accounts[0] }); // Update user context with wallet address
        } else {
          alert('No accounts found');
        }
      } catch (err) {
        if (err.code === 4001) {
          alert('Please connect to Metamask');
        } else {
          console.error('Error connecting wallet:', err);
        }
      } finally {
        setIsConnecting(false);
      }
    } else {
      alert('Please install MetaMask wallet');
    }
  };

  const disconnectWallet = async () => {
    await setUser({ walletAddress: 'Connect wallet' }); // Reset wallet address in context
  };


  return (
    <div>
      {user.walletAddress === 'Connect wallet' || user.walletAddress === undefined ? (
        <button onClick={connectWallet} disabled={isConnecting}>
          {isConnecting ? 'Connecting...' : 'Connect Wallet'}
        </button>
      ) : (
        <div>
          <button onClick={disconnectWallet}>{user.walletAddress}<img src={shutdown} alt="disconnect" className="icons"/></button>
        </div>
      )}
    </div>
  );
};

export default ConnectWallet;
