import React from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/components.css';
import ConnectWallet from './components/ConnectWallet';
import UserContextProvider from './context/UserContextProvider';
import Sidebar from './components/Sidebar'; // Import Sidebar
import MainContent from './components/MainContent'; // Import MainContent
import MenuContextProvider from './context/MenuContextProvider';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-KSR68X6V'
};

TagManager.initialize(tagManagerArgs);

const App = () => {
  return (
    <UserContextProvider>
      <MenuContextProvider>
        <div className="d-flex flex-column min-vh-100">
          <div className="app-container flex-grow-1">
            <div className='m-2'>
              <a href="index.html" className='logo'><h3>FreeCryptoTools<sup>beta</sup></h3></a>
            </div>

            <div className="mt-3 d-flex flex-row-reverse">
              <div className="px-1"><i className="form-group">Make sure you are connected to Ethereum blockchain</i></div>
            </div>
            <div className="mt-3 d-flex flex-row-reverse">
              <div className="p-1"><ConnectWallet /></div>
            </div>
            <hr />

            <Sidebar /><br />
            <MainContent />
          </div>
          <footer className="mt-auto py-3">
            <hr />
            <i className="form-group">
              Raise a topic at <a href="https://freecryptotools.org" target="_blank" rel="noopener noreferrer">https://freecryptotools.org</a> in case of problems or feedback.
            </i>
          </footer>
        </div>
      </MenuContextProvider>
    </UserContextProvider>
  );
};

export default App;
